.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}
.learnMorePoupWrapper {
  .modal-header {
    padding: 10px 10px 0 17px;
    border-bottom: 0;
    margin-bottom: 10px;
    .btn-close {
      font-size: 10px;
    }
  }
  .modal-title {
    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 370px;
    }
  }
  .modal-content {
    width: 100%;
    padding: 5px 10px;
  }
  .modal-body {
    padding-top: 0;
    .text-muted {
      font-size: 13px;
      line-height: 14px;
    }
  }
  .form-label {
    font-weight: 600;
    font-size: 12px;
  }
  input {
    font-size: 12px;
  }
  input[type="submit"] {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1270px) {
  .homeWrapper .bodyWrapper .about {
    padding: 130px 0 30px;
  }
}
