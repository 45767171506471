@font-face {
  font-family: "Avenir-Black";
  src: url(../assets/font/Avenir/Avenir-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir-Light";
  src: url(../assets/font/Avenir/Avenir-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir-Medium";
  src: url(../assets/font/Avenir/Avenir-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir-Regular";
  src: url(../assets/font/Avenir/Avenir-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir-Heavy";
  src: url(../assets/font/Avenir/Avenir-Heavy.ttf) format("truetype");
}

* {
  font-family: "Avenir-Light";
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}
.riley-video {
  cursor: pointer;
}
.iframe-youtube {
  width: 740px;
  height: 420px;
}
.dashboardArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeWrapper .Navbar-mobile .btnGreen,
.homeWrapper .Navbar .right .btnGreen {
  color: #ffffff;
  background: #24a249 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border-color: transparent;
  padding: 5px 0;
  font-size: 14px;
  width: 120px;
  font-family: "Avenir-Heavy";
}
.homeWrapper .bodyWrapper .pricingWrapper .leftArea .btnGreen {
  color: #ffffff;
  background: #24a249 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border-color: transparent;
  padding: 10px 32px;
  font-family: "Avenir-Heavy";
}
.homeWrapper .bodyWrapper .isItForMeWrapper .rightArea .listArea,
.homeWrapper .bodyWrapper .intelligenceWrapper .intelligenceArea .listArea {
  display: grid;
  grid-template-columns: 40px 0.9fr;
  align-items: center;
  row-gap: 25px;
  color: #2e394d;

  font-family: "Avenir-Medium" !important;
}
.homeWrapper .bodyWrapper .isItForMeWrapper .rightArea .listArea .list,
.homeWrapper
  .bodyWrapper
  .intelligenceWrapper
  .intelligenceArea
  .listArea
  .list {
  font-family: "Avenir-Light" !important;
}
.homeWrapper .bodyWrapper .isItForMeWrapper .rightArea .listIcon,
.homeWrapper .bodyWrapper .intelligenceWrapper .intelligenceArea .listIcon {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000000;
  margin-top: 7px;
  align-self: flex-start;
}

.homeWrapper .bodyWrapper .isItForMeWrapper .rightArea .listArea .listName,
.homeWrapper
  .bodyWrapper
  .intelligenceWrapper
  .intelligenceArea
  .listArea
  .listName {
  text-align: left;
  letter-spacing: 0px;
  color: #2e394d;
  opacity: 1;
}
.homeWrapper {
  display: grid;
  grid-template-columns: 1fr;
  .Navbar-mobile {
    display: none;
    .navItems a {
      display: block;
      padding: 10px;
      font-family: "Avenir-Light";
      color: #2e394d;
      text-decoration: none;
    }
  }
  .navWrap {
    display: grid;
    grid-template-columns: 0.4fr auto 0.4fr;
    opacity: 1;
    padding: 10px;
    align-items: center;
    height: 70px;

    .center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        text-align: center;
        font: normal normal 900 14px/19px Avenir;
        letter-spacing: 0px;
        color: #2e394d;
        text-transform: uppercase;
        opacity: 1;
      }
      a {
        color: #2e394d;
        text-decoration: none;
        font-family: "Avenir-Heavy";
        font-size: 14px;
        // font-weight: 550;
      }
    }
    .left {
      justify-self: flex-start;
    }
    .right {
      justify-self: flex-end;
    }
  }
  .Navbar {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000014;
  }
  .bodyWrapper {
    display: grid;
    gap: 180px;
    .about {
      display: grid;
      gap: 20px;
      justify-content: center;
      padding: 0px;
      background: url(../assets/images/brand_circle.png) no-repeat;
      background-position: center;
      background-size: 45%;
      h1 {
        font-family: "Avenir-Black";
        justify-self: center;
        text-align: center;
        margin-bottom: 0;
      }
      .instructions {
        display: grid;
        justify-content: center;
        color: #2e394d;
        text-align: center;
        letter-spacing: 0px;
        opacity: 1;
        font-weight: 550;
        span {
          font-family: "Avenir-Medium";
          font-size: 22px;
        }
      }
      .steps {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        margin: 40px 0;

        .step {
          display: flex;
          align-self: center;
          flex-direction: column;
          align-items: center;
          justify-items: center;
          .logo {
            border-radius: 50%;
            background-color: #dfeffd;
            padding: 10px;
          }
          .heading {
            font-weight: bold;
            padding: 10px 0;
          }
          .detail {
            font-family: "Avenir-Light";
            font-size: 15px;
            font-weight: 21px;
            letter-spacing: 0px;
            color: #2e394d;
            opacity: 1;
          }
        }
      }
      .actionsArea {
        display: grid;
        justify-self: center;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        .btnGreen {
          color: #ffffff;
          background: #24a249 0% 0% no-repeat padding-box;
          border-radius: 6px;
          opacity: 1;
          border-color: transparent;
          padding: 10px 32px;
          font-family: "Avenir-Heavy";
          border: 5px solid #24a249;
        }
        .btnVideo {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;
          text-align: center;
          font-family: "Avenir-Light";
          font-weight: 700;
          letter-spacing: 0px;
          color: #2e394d;
          opacity: 1;
          background: var(--background) 0% 0% no-repeat padding-box;
          border: 1px solid var(--sub-text-1);
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #2e394d;
          border-radius: 4px;
          opacity: 1;
          img {
            margin-right: 5px;
          }
        }
      }
    }
    .videoWrapper {
      position: relative;
      display: flex;
      justify-content: space-around;
      .thumbNail {
        max-width: 100%;
        height: auto;
      }
      .playButton {
        position: absolute;
        top: 38%;
        left: 45%;
        cursor: pointer;
      }
    }
    .uniqueAreaWrapper {
      display: grid;
      justify-content: center;
      gap: 100px;
      .headerArea {
        display: grid;
        gap: 20px;
        justify-self: center;
        .header {
          text-align: left;
          font-family: "Avenir-Light";
          letter-spacing: 0px;
          color: #2e394d;
          opacity: 1;
          font-size: 36px;
          font-weight: 49px;
        }
        .detail {
          text-align: center;
          letter-spacing: 0px;
          color: #2e394d;
          opacity: 1;
        }
      }
      .features {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        row-gap: 70px;
        column-gap: 90px;
        .feature {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-items: center;
          gap: 5px;
          text-align: center;
          width: 30ch;
          .featureName {
            text-align: center;
            font-family: "Avenir-Light";
            font-size: 20px;
            letter-spacing: 0px;
            color: #2e394d;
            font-weight: 500;
            opacity: 1;
          }
          .featureDescription {
            text-align: center;
            letter-spacing: 0px;
            color: #2e394d;
            opacity: 1;
          }
        }
      }
    }
    .intelligenceWrapper {
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      justify-self: center;
      column-gap: 50px;

      .intelligenceArea {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 80px;
        justify-self: end;
        .heading {
          text-align: left;
          font-family: "Avenir-Black";
          font-size: 27px;
          font-weight: 49px;
          letter-spacing: 0px;
          color: #2e394d;
          opacity: 1;
        }
        .description {
          text-align: left;
          letter-spacing: 0px;
          color: #2e394d;
          opacity: 1;
          font-family: "Avenir-Light" !important;
        }
      }
    }
    .isItForMeWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      justify-self: center;
      img.img-fluid {
        align-self: flex-end;
      }
      .rightArea {
        display: flex;
        flex-direction: column;
        padding-top: 40px;

        gap: 20px;
        .heading {
          text-align: left;
          font-family: "Avenir-Black";
          letter-spacing: 0px;
          font-size: 27px;
          font-weight: 49px;
          color: #2e394d;
          opacity: 1;
        }
        .description {
          text-align: left;
          letter-spacing: 0px;
          color: #2e394d;
          opacity: 1;
          font-family: "Avenir-Light" !important;
        }
        .listArea {
          color: #2e394d;
        }
      }
    }
    .pricingTotalWrapper {
      display: flex;
      justify-content: center;
    }
    .pricingWrapper {
      display: grid;
      justify-self: center;
      grid-template-columns: 1fr 2fr;
      .leftArea {
        display: flex;
        gap: 20px;
        flex-direction: column;
        .heading {
          font-family: "Avenir-Black";
          font-size: 27px;
          font-weight: 49px;
          letter-spacing: 0px;
          color: #2e394d;
          opacity: 1;
        }
        .description {
          display: block;
          text-align: left;
          letter-spacing: 0px;
          color: #2e394d;
          opacity: 1;
          margin-bottom: 5px;
          margin-bottom: 15px;
          font-family: "Avenir-Light" !important;
        }
      }
      .rightArea {
        justify-self: center;
      }
    }
    .RileyLoveWrapper {
      display: flex;
      flex-direction: column;
      justify-self: center;
      gap: 70px;
      .header {
        text-align: left;
        font-family: "Avenir-Black";
        font-size: 27px;
        font-weight: 49px;
        letter-spacing: 0px;
        color: #2e394d;
        opacity: 1;
        align-self: center;
      }
      .postArea {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        .post {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 35px;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 17px 40px #0e03360f;
          border-radius: 2px;
          opacity: 1;
          padding: 35px 50px;
          .profileImage {
            width: 120px;
            height: 120px;
          }
          .comments {
            text-align: center;
            letter-spacing: 0px;
            color: #2e394d;
            opacity: 1;
            width: 250px;
            height: 100px;
          }
          @media (max-width: 768px) {
            .comments {
              height: auto;
            }
          }
          .username {
            text-align: center;
            display: block;
            font: normal normal Avenir;
            font-weight: 900;
            font-size: 16px;
            letter-spacing: 0px;
            color: #2e394d;
            opacity: 1;
          }
          .position {
            text-align: center;
            letter-spacing: 0px;
            color: #b1baca;
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }
  .footer {
    background: #f4f6fc 0% 0% no-repeat padding-box;
  }
  .footerArea {
    display: flex;
    padding: 110px 10px;
    gap: 90px;
    background: #f4f6fc 0% 0% no-repeat padding-box;
    opacity: 1;
    .leftArea {
      flex-basis: 60%;
      display: flex;
      gap: 15px;
      flex-direction: column;
      .description {
        text-align: left;
        letter-spacing: 0px;
        color: #2e394d;
        opacity: 1;
      }
    }
    .rightArea {
      .header {
        display: flex;
        align-items: center;
        justify-items: center;
        gap: 15px;
        .description {
          width: 15ch;
        }
      }
      button {
        margin-top: 15px;
        color: #ffffff;
        background: #24a249 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        border-color: transparent;
        padding: 10px 30px;
        font-family: "Avenir-Heavy";
      }
    }
  }
}
.hide-mobile {
  display: block;
}
.visible-mobile {
  display: none !important;
}
@media screen and (max-width: 760px) {
  .instructions span {
    font-size: 18px !important;
  }
  .hide-mobile {
    display: none !important;
  }
  .visible-mobile {
    display: block !important;
  }
  .homeWrapper {
    .Navbar {
      display: none;
    }
    .iframe-youtube {
      width: 95%;
      height: 420px;
    }
    .videoWrapper {
      justify-content: unset;
    }
    .Navbar-mobile {
      display: block;
      background: #fff;
      padding: 20px;
      .nav-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
      }
    }
    .about {
      margin-top: 50px;
      padding-top: 70px !important;
    }

    .intelligenceWrapper,
    .isItForMeWrapper {
      display: block !important;
    }
    .listArea {
      row-gap: 17px !important;
      align-items: flex-start !important;
    }

    .uniqueAreaWrapper {
      .features {
        grid-template-columns: 1fr !important;
      }
      .headerArea .header {
        text-align: center !important;
      }
    }
    .isItForMeWrapper {
      justify-self: center;

      .rightArea {
        margin-top: 50px;
      }
    }
    .postArea {
      flex-direction: column;
    }
    .footerArea {
      margin: auto;
      margin-top: 30px;
      flex-direction: column;
      padding: 20px;
      .empty {
        display: none;
      }
    }
    .steps {
      grid-template-columns: 1fr 30px 1fr 30px 1fr !important;
      margin: 40px 0;
      .step {
        margin-top: 20px;
        .detail {
          font-size: 13px !important;
        }
      }
    }
    .features {
      justify-items: center;
    }
    .post {
      margin-top: 30px;
    }
    .pricingWrapper {
      grid-template-columns: 1fr !important;
      gap: 20px;
    }
    .bodyWrapper {
      gap: 40px;
      grid-gap: 40px;
    }
    .footerArea {
      .rightArea {
        display: flex !important;
        gap: 15px;
        button {
          font-size: 14px;
          padding: 10px 20px;
          margin-left: auto;
        }
      }
    }
    .bodyWrapper {
      .intelligenceWrapper .intelligenceArea {
        margin-top: 0;
      }
      .isItForMeWrapper .rightArea {
        padding-top: 0;
      }
      .RileyLoveWrapper {
        gap: 3px;
        .header {
          margin-top: 20px;
        }
        .postArea .post {
          padding-top: 0;
          height: 420px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1020px) {
  .homeWrapper {
    .Navbar {
      display: none;
    }
    .Navbar-mobile {
      display: block;
      background: #fff;
      padding: 20px;
      .nav-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
      }
    }
    .about {
      margin-top: 50px;
      padding-top: 70px !important;
    }

    .intelligenceWrapper,
    .isItForMeWrapper {
      display: block !important;
    }
    .listArea {
      grid-gap: 0 !important;
      gap: 0 !important;
    }
    .uniqueAreaWrapper {
      .features {
        grid-template-columns: 1fr 1fr !important;
      }
    }
    .isItForMeWrapper {
      justify-self: center;
      .rightArea {
        margin-top: 50px;
      }
    }
    .postArea {
      flex-direction: column;
    }
    .footerArea {
      margin: auto;
      margin-top: 30px;
      flex-direction: row;
      padding: 20px;
      .empty {
        display: none;
      }
    }
    .steps {
      display: grid;
      .step {
        margin-top: 20px;
      }
    }
    .features {
      justify-items: center;
    }
    .post {
      margin-top: 30px;
    }
    .pricingWrapper {
      grid-template-columns: 1fr !important;
      gap: 20px;
    }
    .bodyWrapper {
      gap: 80px;
      grid-gap: 80px;
    }
  }
}
/* Nest Hub :: START */
@media screen and (min-width: 1024px) and (max-width: 1270px) {
  .homeWrapper .Navbar .center a {
    font-size: 14px;
  }
  .homeWrapper .bodyWrapper .uniqueAreaWrapper .features {
    row-gap: 50px;
    column-gap: 10px;
  }
  .homeWrapper .footerArea {
    padding: 40px 0;
  }
  .homeWrapper .bodyWrapper {
    gap: 80px;
    grid-gap: 80px;
  }
  .homeWrapper .bodyWrapper .RileyLoveWrapper .postArea .post {
    padding: 15px;
  }
}
/* Nest Hub Max */
@media screen and (min-width: 1275px) and (max-width: 1295px) {
  .homeWrapper .bodyWrapper .RileyLoveWrapper .postArea .post {
    padding: 35px 40px;
  }
}
